.or {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ced4da;
  line-height: 0.1em;
  margin: -5px 0 20px 0;
}

.or > span {
  background: #fff;
  color: #66686b;
  padding: 0 10px;
}

.card-text {
  height: 15px;
}

.form-group {
  margin-bottom: 1.6rem !important;
  .invalid-feedback {
    margin-bottom: -1.5rem;
  }
}

.exploderDetail {
  min-height: 47px;
  margin-top: -8px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  text-align: center;
  legend {
    margin: 0;
    padding: 0 6px;
    width: auto;
    font-size: 12px;
  }
}

.spinner {
  position: absolute;
  top: 12px;
  right: 15px;
}
