/* Raw CSS is needed to style the scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e3e3e3; /* platinum from colors.ts */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9bacbe; /* cadetBlue from colors.ts */
  border-radius: 1px;
}
